<template>
    <v-container>
        <team-form v-if="showTeam" :teamName="data.teamName" @removeTeamForm="showTeam = false"/>
        <player-form v-for="(player, index) in data.players" :key="index" :playerApplication="player" @removePlayerForm="data.players.splice(index, 1)"/>
    </v-container>
</template>

<script>
    import TeamForm from '@/forms/TeamForm'
    import PlayerForm from '@/forms/PlayerForm'
    import teamApplicationService from '@/services/team-application'

    export default {
        name: 'TeamApplicationForm',
        components: { TeamForm, PlayerForm },
        data: () => {
            return {
                data: null,
                showTeam: true
            }
        },
        created() {
            this.fetchTeamApplication()
        },
        methods: {
            fetchTeamApplication() {
                teamApplicationService.getTeamApplication(this.$route.params.tAId).then(resp => {
                    this.data = resp.data
                })
            }
        }
    }
</script>
