import api from '@/utils/api'

export default {
    getTeamApplications(page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`team-applications` + (page ? range : ''))
    },

    getTeamApplication(id) {
        return api.get(`team-applications/${id}`)
    },

    changeTeamApplicationStatus(id, status) {
        return api.post(`team-applications/${id}`, status)
    },

    deleteTeamApplication(id) {
        return api.delete(`team-applications/${id}`)
    }
}
